<template>
  <div class="base-background" :style="style">
    <slot />
  </div>
</template>

<script setup lang="ts">
import type { IBaseBackgroundProps } from './BaseBackground.types';

const props = defineProps<IBaseBackgroundProps>();
const { borderRadius, height, width } = toRefs(props);

const style = computed(() => ({
  '--border-radius': borderRadius.value ? GlobalUtils.Converting.toPx(borderRadius.value) : 'initial',
  '--height': height?.value ? GlobalUtils.Converting.toPx(height.value) : 'auto',
  '--width': width?.value ? GlobalUtils.Converting.toPx(width.value) : props.defaultWidth ?? '100%',
}));
</script>

<style scoped lang="scss" src="./BaseBackground.scss" />
